// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the theme object.
$front-theme: mat.define-theme(
  (
    color: (
      theme-type: light,
      primary: mat.$cyan-palette,
      tertiary: mat.$blue-palette
    ),
    density: (
      scale: 0
    )
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
:root {
  @include mat.all-component-themes($front-theme);
}

// Comment out the line below if you want to use the pre-defined typography utility classes.
// For more information: https://material.angular.io/guide/typography#using-typography-styles-in-your-application.
// @include mat.typography-hierarchy($front-theme);

// Comment out the line below if you want to use the deprecated `color` inputs.
// @include mat.color-variants-backwards-compatibility($front-theme);
/* You can add global styles to this file, and also import other style files */

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
}

:root {
  --mdc-filled-button-container-height: 52px;
  --mdc-filled-button-container-shape: 8px;
  --mdc-filled-button-label-text-color: #154f5f;
  --mdc-filled-button-container-color: #f5c943;
  --mdc-outlined-button-outline-color: #154f5f;
  --mdc-outlined-button-container-height: 52px;
  --mdc-outlined-button-container-shape: 8px;
  --mdc-fab-container-shape: 999px;
  --mdc-fab-container-color: #d7f1f9;
  --bulma-box-color: #0a222c;
}

@import 'bulma';
h2 {
  font-size: 1.5rem;
  font-weight: 700;
  color: #154f5f;
}
h3 {
  font-size: 1rem;
  font-weight: 700;
  color: #154f5f;
  margin-top: 4px;
  margin-bottom: 4px;
}
h4 {
  font-size: 0.875rem;
  font-weight: 700;
  color: #0a222c;
  margin-top: 4px;
  margin-bottom: 4px;
}
footer,
.footer {
  background-color: #f5fafc !important;
}
.bg-primary {
  background-color: #f9fafb;
}
.bg-white {
  background-color: white;
}
.footer {
  padding: 2rem 0 1.5rem;
}
.width-3rem {
  width: 3rem;
}
.width-4rem {
  width: 4rem;
}
.width-full {
  width: 100%;
}
.icon-green {
  background-color: greenyellow;
}
.error-snackbar {
  --mdc-snackbar-container-color: rgba(244, 67, 54, 0.85);
  max-width: 99dvw !important;
  word-break: break-all;
}
.success-snackbar {
  --mdc-snackbar-container-color: #1d6bce;
}
fieldset {
  background-color: white;
  border-radius: 8px;
  margin-top: 8px;
  margin-bottom: 8px;
  padding: 16px;
}
.br-4 {
  border-radius: 1.5rem;
}
.mat-calendar-table-header th {
  text-align: center !important;
}

// Color of icons
mat-icon[color='alert'] {
  color: #93183f;
}

// Color of icons
mat-icon[color='info'] {
  color: #1d7afc;
}

// color of buttons
.mat-mdc-outlined-button:not(:disabled)[color='secondary'] {
  --mdc-outlined-button-outline-color: #f5c943;
}

//style des liens
a {
  color: #154f5f;
  font-weight: 500;
  font-size: 14px;
}
.gap-s {
  gap: 12px;
}

.gap-xs {
  gap: 8px;
}
.loading {
  animation: loading 2s linear infinite;
  height: 25px;
  width: 25px;
  transform-origin: center;
}

@keyframes loading {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
